import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Metadata from '../Metadata';
import Header from '../Header';
import Footer from '../Footer';
import './styles.scss';

/** The `Layout` component is used to wrap pages with a header and footer */

const Layout = ({ children, title, className, ...other }) => (
  <div className={classNames('layout', className)} {...other}>
    <Metadata title={title} />
    <a className="skip-to-content" href="#content">
      Skip to main content
    </a>
    <Header />
    <main role="main" id="content">
      {children}
    </main>
    <Footer />
  </div>
);

Layout.propTypes = {
  /** The page title */
  title: PropTypes.string.isRequired,
  /** A custom class name */
  className: PropTypes.string,
};

export default Layout;
