import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Column } from '../Grid';
import { Link } from 'gatsby';
import OutboundLink from '../OutboundLink';
import Image from '../Image';
import Icon from '../Icon';
import './styles.scss';

/**
 * A global footer component that is populated with properties from `gatsby-config.js`
 */

const Footer = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          partnerName
          partnerLogo
          partnerUrl
          programName
          aboutProgram
          menuLinks {
            label
            path
          }
        }
      }
    }
  `);

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="footer">
      <div className="footer__top">
        <Container>
          <div className="footer__content">
            <Row>
              <Column size={5} className="footer__top-left">
                <div className="footer__logos">
                  <OutboundLink
                    to="https://www.discoveryeducation.com"
                    target="_blank"
                    className="footer__de-logo"
                  >
                    <Image
                      filename="de-logo-small.png"
                      alt="Discovery Education"
                    />
                  </OutboundLink>
                </div>
              </Column>
              <Column size={6} className="footer__top-right">
                <div className="footer__to_top">
                  <button
                    className="footer__to_top-button"
                    onClick={handleBackToTop}
                  >
                    <span className="footer__to_top-icon">
                      <Icon name="arrowup" />
                    </span>
                  </button>
                </div>
                <ul className="footer__base-list">
                  <li className="footer__base-list-item">
                    <OutboundLink
                      to="/"
                      className="cookie_settings footer__base-link"
                    >
                      Cookie Settings
                    </OutboundLink>
                  </li>
                  <li className="footer__base-list-item">
                    <OutboundLink
                      to="https://www.discoveryeducation.com/terms-of-use/"
                      className="footer__base-link"
                    >
                      Terms of Use
                    </OutboundLink>
                  </li>
                  <li className="footer__base-list-item">
                    <OutboundLink
                      to="https://www.discoveryeducation.com/privacy-policy/"
                      className="footer__base-link"
                    >
                      Privacy Policy
                    </OutboundLink>
                  </li>
                  <li className="footer__base-list-item">
                    <OutboundLink
                      to="https://www.discoveryeducation.com/help/contact-us/"
                      className="footer__base-link"
                    >
                      Contact Us
                    </OutboundLink>
                  </li>
                  <li className="footer__base-list-item">
                    <Link to="/about" className="footer__base-link">
                      About Us
                    </Link>
                  </li>
                </ul>
                <div className="footer__base-list-item copyright">
                  Copyright © 2023 Discovery Education. All rights reserved.
                  Discovery Education, Inc.
                </div>
              </Column>
            </Row>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
